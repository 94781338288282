import Logo from "../public/button-logo.png";
import Image from "next/image";
import { signIn, useSession } from "next-auth/react";
import Loader from "./Loader";
import Sidebar from "./navigation/Sidebar";
import { useRouter } from "next/router";
import { Toaster } from "react-hot-toast";

export default function Layout({ children }) {
  const { data: session, status } = useSession();
  const router = useRouter();

  if (status === "loading") {
    return (
      <div className="dark:bg-gray-900 w-full min-h-screen dark:text-white flex items-center justify-center">
        <Loader size="medium" />
      </div>
    );
  }

  return (
    <div className="dark:bg-gray-900 bg-gray-100 w-full min-h-screen dark:text-gray-100">
      {!session ? (
        <div className="flex min-h-screen items-center justify-center">
          <div className="flex-col">
            <div className="w-40 dark:invert mb-6">
              <Image src={Logo} />
            </div>
            <div className="flex flex-col items-start space-y-4">
              <button
                onClick={() => signIn("okta")}
                className="px-4 py-2 border bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-900 transition-all rounded text-sm font-medium"
              >
                Login as Button Boutique
              </button>
              <button
                onClick={() => signIn("google")}
                className="px-4 py-2 border bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-900 transition-all rounded text-sm font-medium"
              >
                Login as Oscarol
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Toaster />
          {router.pathname !== "/printer" &&
            router.pathname !== "/slip/[id]" && <Sidebar session={session} />}
          <div
            className={`${
              router.pathname !== "/printer" && router.pathname !== "/slip/[id]"
                ? "pl-[320px] p-5"
                : ""
            }`}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
}
