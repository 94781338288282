import Image from "next/image";
import { useRouter } from "next/router";
import Logo from "../../public/button-logo.png";
import {
  TagIcon,
  InboxStackIcon,
  FaceSmileIcon,
  GiftTopIcon,
  QueueListIcon,
  ClipboardDocumentListIcon,
  TruckIcon,
  MagnifyingGlassIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { signOut } from "next-auth/react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Loader from "../../components/Loader";
import { toast } from "react-hot-toast";

export default function Sidebar({ session }) {
  const [search, setSearch] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const router = useRouter();
  const navigation = [
    {
      name: "Labels",
      href: "/labels",
      icon: TagIcon,
    },
    {
      name: "Orders",
      href: "/orders",
      icon: QueueListIcon,
    },
    {
      name: "Create Store Credit/Gift Card",
      href: "https://button-boutique-horwich.myshopify.com/admin/gift_cards/new",
      icon: GiftTopIcon,
      blank: true,
    },
    {
      name: "Loyalty",
      href: "https://app.smile.io/customers",
      icon: FaceSmileIcon,
      blank: true,
    },
    // {
    //   name: "Royal Mail Open Orders",
    //   href: "https://business.parcel.royalmail.com/orders/",
    //   icon: ClipboardDocumentListIcon,
    //   blank: true,
    // },
    // {
    //   name: "Royal Mail Manifests",
    //   href: "https://business.parcel.royalmail.com/manifest-history/",
    //   icon: TruckIcon,
    //   blank: true,
    // },
  ];

  const handleSearchEnter = async (e) => {
    if (e.key == "Enter") {
      setSearchLoading(true);
      const res = await fetch(`/api/search?search=${search}`);
      if (!res.ok) {
        setSearchLoading(false);
        return toast.error(
          "Could not complete your search. Please try again later."
        );
      }
      const data = await res.json();
      setSearchLoading(false);
      if (data[0].order_number) {
        router.push(`/orders/${data[0].id}`);
      } else {
        setSearchResults(data);
      }
    }
  };

  useEffect(() => {
    console.log(searchResults);
  }, [searchResults]);

  return (
    <>
      <div className="flex flex-grow min-h-screen fixed top-0 left-0 w-[300px] flex-col overflow-y-auto border-r border-gray-200 bg-white dark:bg-gray-800 dark:border-gray-700 pt-3 pb-4">
        <div className="flex flex-shrink-0 items-center px-4">
          <Image src={Logo} className="h-16 w-auto dark:invert" />
        </div>
        <div className="mt-3 flex flex-grow flex-col">
          <div className="mb-5 w-full rounded-md px-3">
            <div className="relative w-full">
              <div className="h-full absolute top-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon className="w-4 h-4" />
              </div>
              {searchLoading && (
                <div className="h-full absolute top-0 right-0 pr-3 flex items-center pointer-events-none">
                  <Loader />
                </div>
              )}
              <input
                type="text"
                className="w-full rounded-md text-base border bg-gray-50 py-1.5 pr-2 pl-9 font-medium"
                placeholder="Search here"
                onKeyDown={handleSearchEnter}
                onChange={(e) => {
                  setSearchResults([]);
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <nav
            className="flex-1 space-y-1 bg-white dark:bg-gray-800 px-2"
            aria-label="Sidebar"
          >
            {navigation.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                target={item.blank ? "_blank" : null}
                className={`
                ${
                  router.pathname == item.href
                    ? "bg-gray-100 text-gray-900 dark:text-gray-100 dark:bg-gray-900"
                    : "text-gray-600 dark:text-gray-400 hover:bg-gray-50 hover:text-gray-900 dark:hover:bg-gray-900 dark:hover:text-gray-100"
                }
                group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all
              `}
              >
                <item.icon
                  className={`
                  ${
                    router.pathname == item.href
                      ? "text-gray-500"
                      : "text-gray-400 group-hover:text-gray-500"
                  }
                  mr-3 flex-shrink-0 h-5 w-5
                `}
                  aria-hidden="true"
                />
                <span className="flex-1">{item.name}</span>
                {item.count ? (
                  <span
                    className={`
                    ${
                      router.pathname == item.href
                        ? "bg-white dark:bg-gray-700"
                        : "bg-gray-100 group-hover:bg-gray-200 dark:bg-gray-900 dark:group-hover:bg-gray-800"
                    }
                    ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full
                  `}
                  >
                    {item.count}
                  </span>
                ) : null}
              </Link>
            ))}
          </nav>
        </div>
        <div className="fixed bottom-4 left-4">
          <p className="text-gray-400 text-sm">
            Signed in as {session.user.name}
          </p>
          <button className="font-medium text-sm" onClick={() => signOut()}>
            Sign Out
          </button>
        </div>
      </div>
      <AnimatePresence>
        {searchResults.length > 0 && (
          <motion.div
            className="fixed top-[20px] left-[320px] w-[350px] max-h-[80vh] overflow-y-scroll bg-white p-4 rounded-md shadow-md z-10"
            animate={{ opacity: 1, scale: 1, translateY: 0 }}
            initial={{ opacity: 0, scale: 0.97, translateY: 5 }}
            exit={{ opacity: 0, scale: 0.97, translateY: 5 }}
            transition={{ ease: [0.4, 0.2, 0, 0.1], delay: open ? 0.2 : 0 }}
          >
            <p className="uppercase opacity-60 font-bold text-xs">Search</p>
            <h2 className="text-xl">{search}</h2>
            <div className="mt-4">
              <p className="uppercase opacity-60 font-bold text-xs mb-2">
                Customers
              </p>
              {searchResults.length > 0 ? (
                searchResults.map((customer) => (
                  <Link
                    key={customer.id}
                    href={`/customers/${customer.id}`}
                    className=""
                  >
                    <div className="flex items-center space-x-4 hover:bg-gray-100 px-2 py-1 rounded">
                      <div>
                        <UserIcon className="w-5 h-5" />
                      </div>
                      <div>
                        <p className="font-bold text-gray-600">
                          {customer.first_name} {customer.last_name}
                        </p>
                        <p className="text-sm">{customer.email}</p>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p className="text-gray-500">No customers found</p>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
